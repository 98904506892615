import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { actions } from "../store";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";

import BlockComponent from "./components/BlockComponent";
import ImageComponent from "./components/ImageComponent";

export default function ContactUsEditor() {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings } = useSelector((store) => store.globalState);

    const onChangeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.pages.contact_us.background_color = color;

        setWebSettings(configs);
    };

    const onChangeBackgroundImage = (image) => {
        let configs = { ...webSettings };

        configs.pages.contact_us.background_image = image;

        setWebSettings(configs);
    };

    const onChangeEnabled = (e, key) => {
        let configs = { ...webSettings };

        configs.pages.contact_us[key].enabled = e;

        setWebSettings(configs);
    };

    const onChangeIllustration = (image, key) => {
        let configs = { ...webSettings };

        configs.pages.contact_us[key].icon = image;

        setWebSettings(configs);
    };

    const { background_image, background_color, location, phone, email} =
        webSettings?.pages?.contact_us;

    return (
        <div>
            <EditorDrawer title={"Activation settings"}>
                <ImageComponent
                    title={"Background image"}
                    defaultImage={background_image}
                    updateImage={(e) => onChangeBackgroundImage(e)}
                    deleteImage={() => onChangeBackgroundImage("")}
                />

                <ColorComponent
                    text="Background color"
                    defaultColor={background_color}
                    callback={onChangeBackgroundColor}
                />

                <BlockComponent
                    onOff
                    title={"Our location"}
                    checked={location.enabled}
                    onChange={(e) => onChangeEnabled(e, "location")}
                >
                    <ImageComponent
                        title={"Illustration"}
                        defaultImage={location?.icon}
                        updateImage={(e) => onChangeIllustration(e, "location")}
                        deleteImage={() => onChangeIllustration("", "location")}
                        size={location?.location_size}
                        onChangeSize={(size) => {
                            let configs = { ...webSettings };
                            configs.pages.contact_us.location["location_size"] = +size;
                    
                            setWebSettings(configs);
                        }}
                    />
                </BlockComponent>

                <BlockComponent
                    onOff
                    title={"Phone Number"}
                    checked={phone?.enabled}
                    onChange={(e) => onChangeEnabled(e, "phone")}
                >
                    <ImageComponent
                        title={"Illustration"}
                        defaultImage={phone?.icon}
                        updateImage={(e) => onChangeIllustration(e, "phone")}
                        deleteImage={() => onChangeIllustration("", "phone")}
                        size={phone?.phone_size}
                        onChangeSize={(size) => {
                            let configs = { ...webSettings };
                    
                            configs.pages.contact_us.phone["phone_size"] = +size;
                    
                            setWebSettings(configs);
                        }}
                    />
                </BlockComponent>

                <BlockComponent
                    onOff
                    title={"Email Address"}
                    checked={email?.enabled}
                    onChange={(e) => onChangeEnabled(e, "email")}
                >
                    <ImageComponent
                        title={"Illustration"}
                        defaultImage={email?.icon}
                        updateImage={(e) => onChangeIllustration(e, "email")}
                        deleteImage={() => onChangeIllustration("", "email")}
                        size={email?.email_size}
                        onChangeSize={(size) => {
                            let configs = { ...webSettings };
                    
                            configs.pages.contact_us.email["email_size"] = +size;
                    
                            setWebSettings(configs);
                        }}
                    />
                </BlockComponent>
            </EditorDrawer>
        </div>
    );
}
