import React from "react";
import { useSelector } from "react-redux";

import PageComponent from "../../components/PageComponent";

import "./_referring-pages.scss";

export default function MoreInfoAboutPartner() {
    const selectedLanguage = useSelector((store) => store.globalState.selectedLanguage);

    return (
        <PageComponent>
            {
                selectedLanguage.iso_code == "hy" ?
                    <div className="referring-pages">
                        <div>
                            <h1> Եկեք միասին աշխատենք</h1>
                            <p>
                                Ճկուն, բարձր որակի վրա հիմնված ծրագիրը ձեզ տրամադրում է մրցունակ գներ, որոնք կօգնեն ձեզ վաճառել մեր հավելվածը և ստանալ ձեր ուզած շահույթը:
                            </p>
                        </div>
                        <div>
                            <h1>Գործընկերության նպատակներ</h1>
                            <p>
                            Մեր ծրագրին միանալը նշանակում է լինել մեր թիմի անդամ: Մենք կհոգանք ձեր բիզնեսի բոլոր կարիքները հավելվածի աջակցության առումով, քանի որ մենք լիովին հասկանում ենք, որ ձեր բիզնեսը զարգացնելու համար ավելին է պահանջվում, ինչը նշանակում է, որ մեզ համար կարևոր են մեր գործըներները այնքան, որքան մենք իրենց համար: Անկախ նրանից՝ դուք ցանկանում եք ստեղծել եկամտի նոր հոսք,գոյություն ունեցող հաճախորդների հետ, ձեռք բերել նոր հաճախորդներ՝ համատեղելով մեր հավելվածը ձեր փլեյլիստերի վաճառքի հետ՝ բոլորը մեկ փաթեթով, կամ վերը նշված բոլորը, մենք ունենք այն գործիքները, որոնք ձեզ անհրաժեշտ են դա իրականացնելու համար: .

                            </p>
                        </div>
                        <div>
                            <h1>Գտեք ձեզ ամենահամապատասխանը</h1>
                            <p>
                            Մեր ծրագիրը հիմնված է կարողությունների և ներդրումների վրա: Ձեր բիզնեսը ձևավորեք՝ ընտրելով ձեզ համար հարմար տարբերակը. մենք ձեզ կգտնենք այնտեղ:
                            </p>
                        </div>
                        <div>
                            <h1>Վերավաճառող ծրագիր</h1>
                            <p>
                            Դուք կկարողանաք գնել հավելվածը մրցունակ գնով, որը կնվազի, քանի որ դուք կկարողանաք վաճառել այն ձեր ուզած գնով: Դուք նաև կկարողանաք օգտվել ձեր հաճախորդներին տրամադրվող աջակցությունից և լիովին կվերահսկեք ձեր ակտիվացրած սարքերը:
                            </p>
                        </div>
                        <div>
                            <h1>Գործընկերային ծրագիր</h1>
                            <p>
                            Այն ամենը, ինչ անհրաժեշտ է առանց նախնական ներդրումների՝ գործարկելու համար: Այս ծրագրով մենք ցանկանում ենք հնարավորություն տալ մեր գործընկերներին սկսել առանց նախնական ներդրումների: Դուք կկարողանաք ստեղծել հղումներ, որոնք կարող եք սահմանել ձեռքբերման տարբեր հարթակների համար և հետևել ձեր վիճակագրությանը, ինչպես նաև ունենալ եկամտի հոսք՝ կենտրոնանալով մարքեթինգային գործիքների վրա:
                            </p>
                        </div>
                    </div>
                    :
                    <div className="referring-pages">
                        <div>
                            <h1> Let’s work together</h1>
                            <p>
                                A flexible, value-based program provides you with competitive prices that
                                will help you sell our app and make gains you want.
                            </p>
                        </div>

                        <div>
                            <h1>Partnership goals</h1>
                            <p>
                                Joining our program means being part of our Team. We will take care of all
                                your business needs in terms of support for the app as we fully understand
                                that It takes more than a great product to grow your business—which means we
                                rely on our partners just as much as they rely on us. Whether you’re looking
                                to create new revenue opportunities with existing clients, acquire new
                                customers by combining our app with your playlist sale for an all in one
                                package, or all of the above, we’ve got the tools you need to make it
                                happen.
                            </p>
                        </div>

                        <div>
                            <h1>Find the right fit for you</h1>
                            <p>
                                No two businesses are the same, which is why our program is based on
                                capabilities and contributions. Get your business in shape by choosing the
                                option that works for you—we’ll spot you from there.
                            </p>
                        </div>

                        <div>
                            <h1>Reseller program</h1>
                            <p>
                                You will be able to purchase the app at a competitive price which will go
                                lower as you advance in tears and are able to sell it at the price that you
                                want. You also benefit from support to your customers and have full control
                                over the devices you activate.
                            </p>
                        </div>

                        <div>
                            <h1>Affiliate program</h1>
                            <p>
                                Everything you need to get up and running without an initial investment.
                                With this program we would like to give the opportunity for our partners to
                                start with no initial investment. You’ll be able to generate links that you
                                could set for different acquisition platforms and track your stats as well
                                have a revenue stream just by focusing on marketing.
                            </p>
                        </div>
                    </div>
            }

        </PageComponent>
    );
}
