import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ButtonComponent from '../../components/ButtonComponent';
import TEXT from '../../config/text';
import useTranslation from '../../hooks/useTranslation';

import styles from "./index.module.scss";
import REQUESTS from '../../api/requests';

export default function PricingPage() {
  const selectedLanguage = useSelector((store) => store.globalState.selectedLanguage);

  const [currentPackage, setCurrentPackage] = useState([]);
  
  const [resellerPackages, setResellerPackages] = useState([]);

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.pricing?.[text]) {
      return translation?.pages?.pricing?.[text];
    }

    // return TEXT?.pages?.pricing?.[text];
  };

  const getPackage = () => {
    function callback(data) {
      setCurrentPackage(data.rows);
    }
    function errorCallback() { }

    REQUESTS.GET_PACKAGES(callback, errorCallback);
  };

  const getResellerPackages = () => {
    function callback(data) {
      setResellerPackages(data);
    }
    function errorCallback() { }

    REQUESTS.GET_RESELLER_PACKAGES(callback, errorCallback);
  }

  useEffect(() => {
    getPackage();
    getResellerPackages();
  }, [])
  return (
    <div className={styles['pricing-page']}>
      <div className={styles['pricing-page-wrapper']}>
        {selectedLanguage?.iso_code == "hy" ? <>
          <p className={styles['info']}>i-player-ը մեդիա փլեյեր է՝ կոնտենտ դիտելու համար:</p>
          <p className={styles['info']}>Հավելվածը չի տրամադրում կոնտենտ, դուք պետք է ունենաք ձեր սեփական փլեյլիստը՝ հավելվածն օգտագործելու համար:</p>
          <p className={styles['info']}>Հավելվածն ակտիվացնելու համար անհրաժեշտ է գնալ ակտիվացման էջ և գնել հավելվածը տեղադրված վճարային համակարգի միջոցով:</p>
          <p className={styles['info-price']}>
            LifeTime-ի ակտիվացման արժեքը սարքի համար{"  "} 
            <span className={styles['price']}>4800 դրամ է</span>
          </p>
        </> :
          <>
            <p className={styles['info']}>IPTVplayer.io is a media player for watching a content.</p>
            <p className={styles['info']}> The app does not provide a content, you must have your own playlist to use the app.</p>
            <p className={styles['info']}>For activating the app you need to go to activation page and purchase the app by Stripe or PayPal.</p>
            <p className={styles['info-price']}>
              The cost for LifeTime activation is{" "}
              <span className={styles['price']}>{currentPackage && currentPackage[0] && currentPackage[0].price} $</span>{" "} for a device
            </p>
          </>
        }

        <div className={styles['button-link']}>
          <Link to="/activation" >
            <ButtonComponent title={handleText('activate_app')} />
          </Link>
        </div>

        <div className={styles['divider']}></div>

        <p className={styles['title']}>{handleText("reseller_packaging")}</p>
        {selectedLanguage?.iso_code == "hy" ?
          <p className={styles['subtitle']}>Եթե ցանկանում եք դառնալ գործընկեր, <Link to={'/reseller'} className={styles['register']}>գրանցվեք</Link> և ընտրեք փաթեթ, որը լավագույնս համապատասխանում է ձեզ</p> :
          <p className={styles['subtitle']}>If you want to become a reseller, <Link to={'/reseller'} className={styles['register']}>Register</Link> and choose a package that fits you best.</p>
        }
        <div className={styles['res-packages-container']}>
          {
            resellerPackages && resellerPackages.map(({ id, name, price, count }, index) => {

              let [name1, name2] = name.split("(");

              if (name2) {
                if (selectedLanguage?.iso_code == "hy") {
                  name2 = name2.substring(0, name2.indexOf("$"))
                } else {
                  name2 = name2.replace(")", "");
                }
              }

              return (
                <div key={id} className={styles['res-package']}>
                  <div className={styles['name']}>
                    {name1 || ""}
                    <br />
                    {selectedLanguage?.iso_code == "hy" ? `${name2 * 400} դրամ մեկ սարքի համար` : name2 || ""}
                  </div>
                  {
                    selectedLanguage?.iso_code == "hy" ?
                      <span className={styles['price-block']}>{count} սարքը {price * 400}դրամով
                      </span> :
                      <span className={styles['price-block']}>{count} credit for {price}$</span>
                  }
                </div>
              )
            })
          }
        </div>
        <div className={styles['button-link']}>
          <Link to="/reseller" >
            <ButtonComponent title={handleText('become_a_reseller')} />
          </Link>
        </div>
        <p className={styles['alert']}>{handleText("alert")}</p>
      </div>
    </div>
  )
}
