
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import "./style/phone-input.scss";

export default function PhoneInputComponent({ phone, setPhone, setCountryCode }) {

    const handleOnChange = (value, data) => {
        setPhone(value);
        setCountryCode(data.dialCode);

        console.log(value, "value")
        console.log(data, "data")
    };

    return (
        <div className={'phone-input-component'}>
            <PhoneInput
                country={'us'}
                value={phone}
                onChange={handleOnChange}
                inputStyle={{
                    backgroundColor: 'transparent',
                    border: "none",
                    borderRadius: "0",
                    borderBottom: "1px solid #fff",
                    color: "fff",
                }}
                buttonStyle={{
                    backgroundColor: 'transparent',
                    border: "none",
                }}
                dropdownClass={"phone-flag-dropdown"}
            />
        </div>
    )
}
