import { bindActionCreators } from "redux";

import { useDispatch, useSelector } from "react-redux";

import { Input } from "antd";

import EditorDrawer from "./components/EditorDrawer";
import ColorComponent from "./components/ColorComponent";

import { actions } from "../store";

import BlockComponent from "./components/BlockComponent";
import ImageComponent from "./components/ImageComponent";

import InputTextareaComponent from "./components/InputTextareaComponent";
import { useEffect, useState } from "react";
import InputComponent from "./components/InputComponent";

export default function FooterEditor({ }) {
    const dispatch = useDispatch();

    const { setWebSettings } = bindActionCreators(actions, dispatch);

    const { webSettings, appInfo } = useSelector((store) => store.globalState);

    const [reserved, setReserved] = useState(webSettings?.footer?.reserved?.name || "Inorain.com");
    const [reservedLink, setReservedLink] = useState(webSettings?.footer?.reserved?.link || "https://inorain.com/");

    const onChangePaymentsCards = (e) => {
        let configs = { ...webSettings };

        configs.footer.payments_cards.enabled = e;

        setWebSettings(configs);
    };

    const onChangeReservedName = (e) => {
        let configs = { ...webSettings };

        if (configs?.footer?.reserved) {
            configs.footer.reserved.enabled = e;
        } else {
            configs.footer['reserved'] = {
                enabled: e,
                name: ""
            }
        }

        setWebSettings(configs);
    };

    const onChangeReservedNameInputValue = (e) => {
        let configs = { ...webSettings };

        setReserved(e.target.value)

        if (configs?.footer?.reserved) {
            configs.footer.reserved.name = e.target.value;
        } else {
            configs.footer['reserved'] = {
                name: e.target.value
            }
        }

        setWebSettings(configs);

    };

    const onChangeReservedLink = (e) => {
        let configs = { ...webSettings };

        setReservedLink(e.target.value)

        if (configs?.footer?.reserved) {
            configs.footer.reserved.link = e.target.value;
        } else {
            configs.footer['reserved'] = {
                link: e.target.value
            }
        }

        setWebSettings(configs);
    };

    const onChangeSocialLink = (e, index) => {
        let configs = { ...webSettings };

        configs.footer.social_links[index].link = e;

        setWebSettings(configs);
    };

    const changeBackgroundColor = (color) => {
        let configs = { ...webSettings };

        configs.footer.background_color = color;

        setWebSettings(configs);
    };

    const onChangeSocialLinksEnabled = (e, index) => {
        let configs = { ...webSettings };

        configs.footer.social_links[index].enabled = e;

        setWebSettings(configs);
    };

    const updateImage = (image, index) => {
        let configs = { ...webSettings };

        configs.footer.social_links[index].icon = image;

        setWebSettings(configs);
    };

    const uploadPaymentsImage = (image) => {
        let configs = { ...webSettings };

        configs.footer.payments_cards.image = image;

        setWebSettings(configs);
    };

    useEffect(() => {

        if (webSettings?.footer?.reserved?.enabled) {
            setReserved(webSettings?.footer?.reserved?.name)
            setReservedLink(webSettings?.footer?.reserved?.link)
        } else {
            setReserved('Inorain.com')
            setReservedLink("https://inorain.com/")
        }
    }, [webSettings])

    return (
        <EditorDrawer title={"Footer settings"} footer>
            <ColorComponent
                text="Background color"
                defaultColor={webSettings?.footer?.background_color}
                callback={changeBackgroundColor}
            />

            {webSettings?.footer?.social_links?.map((item, index) => {
                return (
                    <BlockComponent
                        key={index}
                        onOff
                        title={item.name}
                        checked={item.enabled}
                        onChange={(e) => onChangeSocialLinksEnabled(e, index)}
                        fastSupport
                        checkedFastSupport={item.fast_support}
                        onChangeFastSupport={(e) =>{
                            let configs = { ...webSettings };
        
                            configs.footer.social_links[index].fast_support = e;
                    
                            setWebSettings(configs);
                        }}
                    >
                        <ImageComponent
                            title={"Icon"}
                            defaultImage={item?.icon}
                            updateImage={(e) => updateImage(e, index)}
                            deleteImage={() => updateImage("", index)}
                        />
                        <InputTextareaComponent
                            title="Link"
                            rows={1}
                            value={item?.link}
                            onChange={(e) => onChangeSocialLink(e, index)}
                        />
                    </BlockComponent>
                );
            })}

            <BlockComponent
                onOff
                title="Payments cards"
                checked={webSettings?.footer?.payments_cards?.enabled}
                onChange={onChangePaymentsCards}
            >
                <ImageComponent
                    title={"Image"}
                    disabled={!webSettings?.footer?.payments_cards?.enabled}
                    defaultImage={webSettings?.footer?.payments_cards?.image}
                    updateImage={(e) => uploadPaymentsImage(e)}
                    deleteImage={() => uploadPaymentsImage("")}
                />
            </BlockComponent>
            <BlockComponent
                onOff
                title="Reserved"
                checked={webSettings?.footer?.reserved?.enabled}
                onChange={onChangeReservedName}
            >
                <InputComponent
                    onChange={onChangeReservedNameInputValue}
                    value={reserved}
                    placeholder="Name"
                    disabled={!webSettings?.footer?.reserved?.enabled}
                />
                <InputComponent
                    placeholder="Link"
                    value={reservedLink}
                    onChange={onChangeReservedLink}
                    disabled={!webSettings?.footer?.reserved?.enabled}
                />

            </BlockComponent>
        </EditorDrawer>
    );
}
