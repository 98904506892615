import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Form, Input } from "antd";

import useTranslation from "../../hooks/useTranslation";

import ContactUsInfo from "./ContactUsInfo";

import TEXT from "../../config/text";

import REQUESTS from "../../api/requests";

import ContactUsEditor from "../../editors/ContactUsEditor";

import ButtonComponent from "../../components/ButtonComponent";

import style from "./style/contact.module.scss";
import SuccessModal from "../../components/SuccessModal";

export default function ContactUs() {
    const translation = useTranslation();

    const { webSettings } = useSelector((state) => state.globalState);

    const isAdmin = useSelector((store) => store.globalState.isAdmin);

    const [isSeneding, setIsSending] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);

    const [form] = Form.useForm();

    const handleText = (text) => {
        if (translation?.pages?.contact_us?.[text]) {
            return translation?.pages?.contact_us?.[text];
        }

        return TEXT?.pages?.contact_us?.[text];
    };

    const onFinish = (values) => {
        setIsSending(true);

        const body = {
            title: values.title,
            email: values.email,
            message: values.message,
        };

        function callback() {
            setIsSending(false);
            setSuccessMessage(handleText("submition_success_message"))
            form.resetFields();
        }

        function errorCallback() {
            setIsSending(false);
            form.resetFields();
        }

        REQUESTS.SEND_EMAIL(body, callback, errorCallback);
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }, []);

    const pageStyle = {
        backgroundImage: `url(${webSettings?.pages?.contact_us?.background_image})`,
        backgroundColor: webSettings?.pages?.contact_us?.background_color,
        backgroundSize: "cover",
    };

    return (
        <div className={style["contact-us_main"]} style={pageStyle}>
            {isAdmin && <ContactUsEditor />}
            <div className="container">
                <div className={style["contact-us_wrapper"]}>
                    <div className={style["info-form_wrapper"]}>
                        <ContactUsInfo />
                        <Form className={"contact-form"} form={form} onFinish={onFinish}>
                            <Form.Item
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: handleText("title_input_message"),
                                    },
                                ]}
                            >
                                <div style={{ border: '1px solid rgba(7, 64, 76, 0.3)', borderRadius: 5 }}>
                                    <Input
                                        className="title-input"
                                        placeholder={handleText("title_input_placeholder")}

                                    />
                                </div>
                            </Form.Item>


                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: "email",
                                        message: handleText("email_input_message"),
                                    },
                                ]}
                            >
                                <div style={{ border: '1px solid rgba(7, 64, 76, 0.3)', borderRadius: 5 }}>
                                    <Input
                                        className="title-input"
                                        placeholder={handleText("email_input_placeholder")}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item
                                name="message"
                                rules={[
                                    {
                                        required: true,

                                        message: handleText("message_input_message"),
                                    },
                                ]}
                            >
                                <div style={{ border: '1px solid rgba(7, 64, 76, 0.3)', borderRadius: 5 }}>
                                    <Input.TextArea
                                        className="title-input"
                                        rows={4}
                                        placeholder={handleText("message_input_placeholder")}
                                        style={{ resize: "none" }}
                                    />
                                </div>
                            </Form.Item>

                            <Form.Item shouldUpdate>
                                <ButtonComponent
                                    loading={isSeneding}
                                    title={handleText("button_title")}
                                    onClick={() => form.submit()}
                                    size="large"
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <SuccessModal
                message={successMessage}
                visible={successMessage ? true : false}
                onClose={() => {
                    setSuccessMessage(null);
                }}
            />
        </div>
    );
}
