import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Checkbox } from "antd";
import ButtonComponent from "../../components/ButtonComponent";
import useTranslation from "../../hooks/useTranslation";

import REQUESTS from "../../api/requests";
import TEXT from "../../config/text";
import ErrorModal from "../../components/ErrorModal";
import "./style/modal.scss";
import style from "./style/style.module.scss";
import InputComponent from "../../components/InputComponent";
import { useFetcher } from "react-router-dom";

export default function EditPlaylist({
  visible,
  onCancel,
  getPlaylists,
  playlist,
  protectedPlaylist,
  setProtectedPlaylist,
  pin,
}) {
  const [isProtected, setIsProtected] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [playlistName, setPlaylistName] = useState("");

  const [form] = Form.useForm();

  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.login?.[text]) {
      return translation?.pages?.playlist?.[text];
    }

    return TEXT?.pages?.playlist?.[text];
  };

  const onFinish = (values) => {
    setErrorMessage(null);

    const body = {
      id: playlist.id,
      // is_protected: isProtected,
      name: values.name,
      url: values.url,
      epg_url: values.epg_url || "",
    };


    if (protectedPlaylist && protectedPlaylist.is_protected) {
      body["pin"] = pin;

      if (!isProtected) {
        body["is_protected"] = isProtected;
        setProtectedPlaylist(null)
      } else if (values.pin && values.confirm_pin) {
        body["new_pin"] = values.pin;
        body["confirm_pin"] = values.confirm_pin;
      }
    }

    if (values.pin && values.confirm_pin && isProtected && !protectedPlaylist) {
      body["new_pin"] = values.pin;
      body["confirm_pin"] = values.confirm_pin;
      body["is_protected"] = isProtected;
    }

    function callback(data) {
      getPlaylists();
      onCancel();
    }

    function errorCallback(error) {
      setErrorMessage(error.message);
    }

    REQUESTS.PLAYLIST.EDIT(body, callback, errorCallback);

  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.name) {
      setPlaylistName(changedValues.name)
    }
  };

  // useEffect(()=>{
  //   if (playlistName && playlistName.length > 30) {
  //   setErrorMessage("Name must be maximum 30 characters");
  // }
  // },[playlistName])

  useEffect(() => {
    if (visible) {
      form.setFields([
        { name: "name", value: playlist.name },
        {
          name: "url",
          value: protectedPlaylist ? protectedPlaylist.url : playlist.url,
        },
        {
          name: "epg_url",
          value: protectedPlaylist ? protectedPlaylist.epg_url : playlist.epg_url,
        },
        {
          name: "pin",
          value: protectedPlaylist ? protectedPlaylist.pin : playlist.pin,
        },
        {
          name: "confirm_pin",
          value: protectedPlaylist ? protectedPlaylist.pin : playlist.pin,
        },
      ]);
    } else {
      setIsProtected(false);
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    if (protectedPlaylist) setIsProtected(true);
  }, [protectedPlaylist]);

  return (
    <div>
      <Modal
        closable={false}
        open={visible}
        onCancel={onCancel}
        className="playlist-modal"
        footer={null}
      >
        <div className="playlist-edit-modal">
          <h1 className="playlist-modal-title">
            {handleText("edit_playlist_modal_title")}
          </h1>
          <Form
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            className={style["edit-modal-form"]}
          >
            <div style={{ width: "100%" }}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: handleText("code_input_placeholder"),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: handleText("code_input_placeholder"),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="epg_url"
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
                        callback('Please enter a valid URL');
                      } else {
                        callback();
                      }
                    }
                  },
                ]}
              >
                <InputComponent placeholder={handleText("playlist_epg_url")} />
              </Form.Item>

              <Checkbox
                checked={isProtected}
                onChange={(e) => setIsProtected(e.target.checked)}
                className={style["protected-playlist-checkbox"]}
              >
                {handleText("protect_playlist")}
              </Checkbox>
              <Form.Item
                name="pin"
                rules={[
                  {
                    required: protectedPlaylist ? false : isProtected,
                    message: "Please input your password!",
                  },
                ]}
              >
                <InputComponent
                  type="password"
                  disabled={!isProtected}
                  placeholder={handleText("set_pin")}
                />
              </Form.Item>

              <Form.Item
                name="confirm_pin"
                dependencies={["pin"]}
                rules={[
                  {
                    required: protectedPlaylist ? false : isProtected,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("pin") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <InputComponent
                  type="password"
                  disabled={!isProtected}
                  placeholder={handleText("confirm_pin")}
                />
              </Form.Item>
              <div style={{ marginTop: "40px", textAlign: "center" }}>
                <ButtonComponent
                  title={handleText("SAVE")}
                  onClick={() => {
                    if (playlistName && playlistName.length > 30) {
                      setErrorMessage("Name must be maximum 30 characters");
                    } else {
                      form.submit()
                    }
                  }}
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </div>
  );
}
