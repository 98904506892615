import { useState } from 'react';
import { Spin, } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from "./style/payment.module.scss";
import EmailModal from './components/EmailModal';

export default function CMIPayment({ payWithCMI }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    return (<>
        <div
            className={styles["cmi-payment"]}
            onClick={() => { setOpen(true) }}
            style={{ cursor: loading ? "not-allowed" : "pointer" }}
        >
            {
                loading &&
                <Spin
                    indicator={
                        <LoadingOutlined
                            style={{
                                fontSize: 17,
                            }}
                            spin
                        />
                    }
                />
            }
            CMI
        </div>
        <EmailModal
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            pay={(email, name) => {
                payWithCMI(email, name);

                setTimeout(() => {
                    setLoading(false);
                }, 3000);
            }}
            loading={loading}
            setLoading={setLoading}
            actionType="cmi"
        />
    </>)
}
