import { useState } from 'react';

import { Form, Input } from 'antd';

import ModalComponent from '../../../components/ModalComponent';

import ButtonComponent from '../../../components/ButtonComponent';

import PhoneInputComponent from '../../../components/PhoneInput';

import styles from "../style/_payment.module.scss";
import { useEffect } from 'react';

export default function MyFatooraModal({ open, onCancel, pay }) {
    const [form] = Form.useForm();

    const [phone, setPhone] = useState('');

    const [countryCode, setCountryCode] = useState('');

    const onFinish = (values) => {
        const { email, name } = values;

        pay(email, name, countryCode, phone);
    };

    const onClose = () => {
        onCancel();
        setPhone('');
        setCountryCode('');
        form.resetFields();
    }

    useEffect(() => {
        if (phone) {
            form.setFieldsValue({
                phone: phone
            })
        }
    }, [phone])

    return (
        <div>
            <ModalComponent
                open={open}
                onCancel={onClose}
                destroyOnClose={true}
            >
                <div className={styles['modal-container']}>
                    <Form
                        layout='vertical'
                        form={form}
                        onFinish={onFinish}
                    >
                        <div >
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your name!'
                                    }
                                ]}>
                                <Input
                                    placeholder="Name"
                                />
                            </Form.Item>

                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid email!'
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    }
                                ]}>
                                <Input
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your phone number!'
                                    }
                                ]}
                            >
                                <PhoneInputComponent
                                    phone={phone}
                                    setPhone={setPhone}
                                    setCountryCode={setCountryCode}
                                />
                            </Form.Item>
                            <Form.Item style={{ textAlign: "center", marginTop: 30 }}>
                                <ButtonComponent
                                    onClick={() => {
                                        form.submit()
                                    }}
                                    title="Next"
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </ModalComponent>
        </div>
    )
}
