import React from 'react'

import { Form, Input } from 'antd'

import ModalComponent from '../../../components/ModalComponent'

import ButtonComponent from '../../../components/ButtonComponent'

import style from "../style/payment.module.scss"

export default function EmailModal({ onCancel, open, pay, actionType, loading = false, setLoading = () => { } }) {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        const { name, email } = values;

        onCancel();

        pay(email, name);
    }

    return (
        <ModalComponent open={open} onCancel={() => {
            onCancel();
            form.resetFields();
        }}>
            <div className={style['pixi-email-container']}>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={onFinish}
                >
                    <div >
                        <h3 className={style['pixi-email-title']}>Enter your {actionType == "cmi" ? "name and " : ""} email</h3>
                        {
                            actionType == "cmi" && <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your name!'
                                    }
                                ]}>
                                <Input
                                    placeholder="Name"
                                />
                            </Form.Item>
                        }

                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid email!'
                                },
                                {
                                    required: true,
                                    message: 'Please input your email!'
                                }
                            ]}>
                            <Input
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center", margin: 0 }}>
                            <ButtonComponent
                                onClick={() => {
                                    setLoading(true)
                                    form.submit()
                                }}
                                title="Next"
                                disabled={loading}
                            />
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </ModalComponent>
    )
}
