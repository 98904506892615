import PropTypes from "prop-types";

import useTranslation from "../hooks/useTranslation";

import TEXT from "../config/text";
import IMAGES from "../config/images";

import ModalComponent from "./ModalComponent";
import ButtonComponent from "./ButtonComponent";

export default function SuccessModal({ visible, onClose, message, customButton = false }) {
    const translation = useTranslation();

    const handleText = (text) => {
        if (translation?.pages?.register?.[text]) {
            return translation?.pages?.register?.[text];
        }

        return TEXT?.pages?.register?.[text];
    };

    return (
        <ModalComponent open={visible} onCancel={onClose}>
            <div className="success-modal">
                <img src={IMAGES.SUCCESS} alt="" />
                <p>{message}</p>
                {customButton ? (
                    customButton
                ) : (
                    <ButtonComponent type="primary" onClick={onClose} title={handleText("close")} />
                )}
            </div>
        </ModalComponent>
    );
}

SuccessModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
