
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { HOST } from '../../config';
import requestQuery from '../../api/requestQuery';

import IMAGES from '../../config/images';

import MyFatooraModal from './components/MyFatooraModal';

import styles from "./style/_payment.module.scss";

export default function MyFatoorahPayment({ mac, package_id }) {

    const [open, setOpen] = useState(false);

    const [searchParams] = useSearchParams();

    const pay = (email, name, countryCode, phone = "") => {

        const query = {
            mac,
            package_id,
            customer_email: email,
            customer_name: name,
            mobile_country_code: countryCode,
            customer_mobile: phone.replace(countryCode, "").trim(),
        };

        let referralLinkCode = searchParams.get('referral_link_code');

        if (referralLinkCode) {
            query.referral_link_code = referralLinkCode;
        };

        window.location.href = `${HOST}payment/fatoorah/checkout` + requestQuery(query);
    }

    return (
        <div>
            <div className={styles["my-fatoorah-payment-button"]} onClick={() => setOpen(true)}>
                <img src={IMAGES.MY_FATOORAH} /> My Fatoorah
            </div>
            <MyFatooraModal
                open={open}
                onCancel={() => setOpen(false)}
                pay={pay}
            />
        </div>
    )
}
